const nacionalidades = [
  {
    país: "Afeganistão",
    nacionalidade: "Afegão",
  },
  {
    país: "África do Sul",
    nacionalidade: "Sul-africano",
  },
  {
    país: "Albânia",
    nacionalidade: "Albanês",
  },
  {
    país: "Alemanha",
    nacionalidade: "Alemão",
  },
  {
    país: "Andorra",
    nacionalidade: "Andorrano",
  },
  {
    país: "Angola",
    nacionalidade: "Angolano",
  },
  {
    país: "Antígua e Barbuda",
    nacionalidade: "Antiguano",
  },
  {
    país: "Arábia Saudita",
    nacionalidade: "Saudita",
  },
  {
    país: "Argélia",
    nacionalidade: "Argelino",
  },
  {
    país: "Argentina",
    nacionalidade: "Argentino",
  },
  {
    país: "Armênia",
    nacionalidade: "Armênio",
  },
  {
    país: "Austrália",
    nacionalidade: "Australiano",
  },
  {
    país: "Áustria",
    nacionalidade: "Austríaco",
  },
  {
    país: "Azerbaijão",
    nacionalidade: "Azerbaijano",
  },
  {
    país: "Bahamas",
    nacionalidade: "Bahamiano",
  },
  {
    país: "Bahrein",
    nacionalidade: "Barenita",
  },
  {
    país: "Bangladesh",
    nacionalidade: "Bengali",
  },
  {
    país: "Barbados",
    nacionalidade: "Barbadense",
  },
  {
    país: "Bélgica",
    nacionalidade: "Belga",
  },
  {
    país: "Belize",
    nacionalidade: "Belizenho",
  },
  {
    país: "Benin",
    nacionalidade: "Beninense",
  },
  {
    país: "Bielorrússia",
    nacionalidade: "Bielorrusso",
  },
  {
    país: "Bolívia",
    nacionalidade: "Boliviano",
  },
  {
    país: "Bósnia e Herzegovina",
    nacionalidade: "Bósnio",
  },
  {
    país: "Botsuana",
    nacionalidade: "Bostuanês",
  },
  {
    país: "Brasil",
    nacionalidade: "Brasileiro",
  },
  {
    país: "Brunei",
    nacionalidade: "Bruneano",
  },
  {
    país: "Bulgária",
    nacionalidade: "Búlgaro",
  },
  {
    país: "Burkina Faso",
    nacionalidade: "Burquinês",
  },
  {
    país: "Burundi",
    nacionalidade: "Burundinês",
  },
  {
    país: "Butão",
    nacionalidade: "Butanês",
  },
  {
    país: "Cabo Verde",
    nacionalidade: "Caboverdiano",
  },
  {
    país: "Camarões",
    nacionalidade: "Camaronês",
  },
  {
    país: "Camboja",
    nacionalidade: "Cambojano",
  },
  {
    país: "Canadá",
    nacionalidade: "Canadense",
  },
  {
    país: "Catar",
    nacionalidade: "Catari",
  },
  {
    país: "Cazaquistão",
    nacionalidade: "Cazaque",
  },
  {
    país: "Chade",
    nacionalidade: "Chadiano",
  },
  {
    país: "Chile",
    nacionalidade: "Chileno",
  },
  {
    país: "China",
    nacionalidade: "Chinês",
  },
  {
    país: "Chipre",
    nacionalidade: "Cipriota",
  },
  {
    país: "Colômbia",
    nacionalidade: "Colombiano",
  },
  {
    país: "Comores",
    nacionalidade: "Comoriano",
  },
  {
    país: "Congo",
    nacionalidade: "Congolês",
  },
  {
    país: "Coreia do Norte",
    nacionalidade: "Norte-coreano",
  },
  {
    país: "Coreia do Sul",
    nacionalidade: "Sul-coreano",
  },
  {
    país: "Costa do Marfim",
    nacionalidade: "Marfinense",
  },
  {
    país: "Costa Rica",
    nacionalidade: "Costarriquenho",
  },
  {
    país: "Croácia",
    nacionalidade: "Croata",
  },
  {
    país: "Cuba",
    nacionalidade: "Cubano",
  },
  {
    país: "Dinamarca",
    nacionalidade: "Dinamarquês",
  },
  {
    país: "Djibuti",
    nacionalidade: "Djibutiano",
  },
  {
    país: "Dominica",
    nacionalidade: "Dominiquense",
  },
  {
    país: "Egito",
    nacionalidade: "Egípcio",
  },
  {
    país: "Emirados Árabes Unidos",
    nacionalidade: "Emiradense",
  },
  {
    país: "Equador",
    nacionalidade: "Equatoriano",
  },
  {
    país: "Eritreia",
    nacionalidade: "Eritreu",
  },
  {
    país: "Eslováquia",
    nacionalidade: "Eslovaco",
  },
  {
    país: "Eslovênia",
    nacionalidade: "Esloveno",
  },
  {
    país: "Espanha",
    nacionalidade: "Espanhol",
  },
  {
    país: "Estados Unidos",
    nacionalidade: "Estadunidense",
  },
  {
    país: "Estônia",
    nacionalidade: "Estoniano",
  },
  {
    país: "Etiópia",
    nacionalidade: "Etíope",
  },
  {
    país: "Fiji",
    nacionalidade: "Fijiano",
  },
  {
    país: "Filipinas",
    nacionalidade: "Filipino",
  },
  {
    país: "Finlândia",
    nacionalidade: "Finlandês",
  },
  {
    país: "França",
    nacionalidade: "Francês",
  },
  {
    país: "Gabão",
    nacionalidade: "Gabonês",
  },
  {
    país: "Gâmbia",
    nacionalidade: "Gambiano",
  },
  {
    país: "Gana",
    nacionalidade: "Ganês",
  },
  {
    país: "Geórgia",
    nacionalidade: "Georgiano",
  },
  {
    país: "Granada",
    nacionalidade: "Granadino",
  },
  {
    país: "Grécia",
    nacionalidade: "Grego",
  },
  {
    país: "Guatemala",
    nacionalidade: "Guatemalteco",
  },
  {
    país: "Guiana",
    nacionalidade: "Guianense",
  },
  {
    país: "Guiné",
    nacionalidade: "Guineano",
  },
  {
    país: "Guiné Equatorial",
    nacionalidade: "Guinéu-equatoriano",
  },
  {
    país: "Guiné-Bissau",
    nacionalidade: "Guineense",
  },
  {
    país: "Haiti",
    nacionalidade: "Haitiano",
  },
  {
    país: "Holanda",
    nacionalidade: "Holandês",
  },
  {
    país: "Honduras",
    nacionalidade: "Hondurenho",
  },
  {
    país: "Hungria",
    nacionalidade: "Húngaro",
  },
  {
    país: "Iêmen",
    nacionalidade: "Iemenita",
  },
  {
    país: "Ilhas Marshall",
    nacionalidade: "Marshalesa",
  },
  {
    país: "Índia",
    nacionalidade: "Indiano",
  },
  {
    país: "Indonésia",
    nacionalidade: "Indonésio",
  },
  {
    país: "Irã",
    nacionalidade: "Iraniano",
  },
  {
    país: "Iraque",
    nacionalidade: "Iraquiano",
  },
  {
    país: "Irlanda",
    nacionalidade: "Irlandês",
  },
  {
    país: "Islândia",
    nacionalidade: "Islandês",
  },
  {
    país: "Israel",
    nacionalidade: "Israelense",
  },
  {
    país: "Itália",
    nacionalidade: "Italiano",
  },
  {
    país: "Jamaica",
    nacionalidade: "Jamaicano",
  },
  {
    país: "Japão",
    nacionalidade: "Japonês",
  },
  {
    país: "Jordânia",
    nacionalidade: "Jordaniano",
  },
  {
    país: "Kiribati",
    nacionalidade: "Kiribatiano",
  },
  {
    país: "Kuwait",
    nacionalidade: "Kuwaitiano",
  },
  {
    país: "Laos",
    nacionalidade: "Laosiano",
  },
  {
    país: "Lesoto",
    nacionalidade: "Lesotiano",
  },
  {
    país: "Letônia",
    nacionalidade: "Letão",
  },
  {
    país: "Líbano",
    nacionalidade: "Libanês",
  },
  {
    país: "Libéria",
    nacionalidade: "Liberiano",
  },
  {
    país: "Líbia",
    nacionalidade: "Líbio",
  },
  {
    país: "Liechtenstein",
    nacionalidade: "Liechtensteiniano",
  },
  {
    país: "Lituânia",
    nacionalidade: "Lituano",
  },
  {
    país: "Luxemburgo",
    nacionalidade: "Luxemburguês",
  },
  {
    país: "Macedônia do Norte",
    nacionalidade: "Macedônio",
  },
  {
    país: "Madagascar",
    nacionalidade: "Malgaxe",
  },
  {
    país: "Malásia",
    nacionalidade: "Malaio",
  },
  {
    país: "Malauí",
    nacionalidade: "Malauiano",
  },
  {
    país: "Maldivas",
    nacionalidade: "Maldiviano",
  },
  {
    país: "Mali",
    nacionalidade: "Maliano",
  },
  {
    país: "Malta",
    nacionalidade: "Maltês",
  },
  {
    país: "Marrocos",
    nacionalidade: "Marroquino",
  },
  {
    país: "Maurício",
    nacionalidade: "Mauriciano",
  },
  {
    país: "Mauritânia",
    nacionalidade: "Mauritano",
  },
  {
    país: "México",
    nacionalidade: "Mexicano",
  },
  {
    país: "Micronésia",
    nacionalidade: "Micronésio",
  },
  {
    país: "Moçambique",
    nacionalidade: "Moçambicano",
  },
  {
    país: "Moldávia",
    nacionalidade: "Moldavo",
  },
  {
    país: "Mônaco",
    nacionalidade: "Monegasco",
  },
  {
    país: "Mongólia",
    nacionalidade: "Mongol",
  },
  {
    país: "Montenegro",
    nacionalidade: "Montenegrino",
  },
  {
    país: "Namíbia",
    nacionalidade: "Namibiano",
  },
  {
    país: "Nauru",
    nacionalidade: "Nauruano",
  },
  {
    país: "Nepal",
    nacionalidade: "Nepalês",
  },
  {
    país: "Nicarágua",
    nacionalidade: "Nicaraguense",
  },
  {
    país: "Níger",
    nacionalidade: "Dnigerino",
  },
  {
    país: "Nigéria",
    nacionalidade: "Nigeriano",
  },
  {
    país: "Noruega",
    nacionalidade: "Norueguês",
  },
  {
    país: "Nova Zelândia",
    nacionalidade: "Neozelandês",
  },
  {
    país: "Omã",
    nacionalidade: "Omani",
  },
  {
    país: "Palau",
    nacionalidade: "Palauano",
  },
  {
    país: "Panamá",
    nacionalidade: "Panamenho",
  },
  {
    país: "Papua Nova Guiné",
    nacionalidade: "Papuásio",
  },
  {
    país: "Paquistão",
    nacionalidade: "Paquistanês",
  },
  {
    país: "Paraguai",
    nacionalidade: "Paraguaio",
  },
  {
    país: "Peru",
    nacionalidade: "Peruano",
  },
  {
    país: "Polônia",
    nacionalidade: "Polonês",
  },
  {
    país: "Portugal",
    nacionalidade: "Português",
  },
  {
    país: "Quênia",
    nacionalidade: "Queniano",
  },
  {
    país: "Quirguistão",
    nacionalidade: "Quirguiz",
  },
  {
    país: "Reino Unido",
    nacionalidade: "Britânico",
  },
  {
    país: "República Centro-Africana",
    nacionalidade: "Centro-africano",
  },
  {
    país: "República Checa",
    nacionalidade: "Tcheco",
  },
  {
    país: "República Democrática do Congo",
    nacionalidade: "Congolês",
  },
  {
    país: "República Dominicana",
    nacionalidade: "Dominicano",
  },
  {
    país: "Romênia",
    nacionalidade: "Romeno",
  },
  {
    país: "Ruanda",
    nacionalidade: "Ruandês",
  },
  {
    país: "Rússia",
    nacionalidade: "Russo",
  },
  {
    país: "Salomão",
    nacionalidade: "Salomônico",
  },
  {
    país: "Salvador",
    nacionalidade: "Salvadorenho",
  },
  {
    país: "Samoa",
    nacionalidade: "Samoano",
  },
  {
    país: "Santa Lúcia",
    nacionalidade: "Santa-lucense",
  },
  {
    país: "São Cristóvão e Neves",
    nacionalidade: "São-cristovense",
  },
  {
    país: "São Marinho",
    nacionalidade: "São-marinense",
  },
  {
    país: "São Tomé e Príncipe",
    nacionalidade: "Santomense",
  },
  {
    país: "São Vicente e Granadinas",
    nacionalidade: "São-vicentino",
  },
  {
    país: "Seicheles",
    nacionalidade: "Seichelense",
  },
  {
    país: "Senegal",
    nacionalidade: "Senegalês",
  },
  {
    país: "Serra Leoa",
    nacionalidade: "Serra-leonês",
  },
  {
    país: "Sérvia",
    nacionalidade: "Sérvio",
  },
  {
    país: "Singapura",
    nacionalidade: "Singapuriano",
  },
  {
    país: "Síria",
    nacionalidade: "Sírio",
  },
  {
    país: "Somália",
    nacionalidade: "Somali",
  },
  {
    país: "Sri Lanka",
    nacionalidade: "Cingalês",
  },
  {
    país: "Suazilândia",
    nacionalidade: "Suazi",
  },
  {
    país: "Sudão",
    nacionalidade: "Sudanês",
  },
  {
    país: "Sudão do Sul",
    nacionalidade: "Sudanês do sul",
  },
  {
    país: "Suécia",
    nacionalidade: "Sueco",
  },
  {
    país: "Suíça",
    nacionalidade: "Suíço",
  },
  {
    país: "Suriname",
    nacionalidade: "Surinamês",
  },
  {
    país: "Tailândia",
    nacionalidade: "Tailandês",
  },
  {
    país: "Taiwan",
    nacionalidade: "Taiwanês",
  },
  {
    país: "Tajiquistão",
    nacionalidade: "Tadjique",
  },
  {
    país: "Tanzânia",
    nacionalidade: "Tanzaniano",
  },
  {
    país: "Timor-Leste",
    nacionalidade: "Timorense",
  },
  {
    país: "Togo",
    nacionalidade: "Togolês",
  },
  {
    país: "Tonga",
    nacionalidade: "Tonganês",
  },
  {
    país: "Trinidad e Tobago",
    nacionalidade: "Trinitário",
  },
  {
    país: "Tunísia",
    nacionalidade: "Tunisiano",
  },
  {
    país: "Turcomenistão",
    nacionalidade: "Turcomano",
  },
  {
    país: "Turquia",
    nacionalidade: "Turco",
  },
  {
    país: "Tuvalu",
    nacionalidade: "Tuvaluano",
  },
  {
    país: "Ucrânia",
    nacionalidade: "Ucraniano",
  },
  {
    país: "Uganda",
    nacionalidade: "Ugandense",
  },
  {
    país: "Uruguai",
    nacionalidade: "Uruguaio",
  },
  {
    país: "Uzbequistão",
    nacionalidade: "Uzbeque",
  },
  {
    país: "Vanuatu",
    nacionalidade: "Vanuatuense",
  },
  {
    país: "Vaticano",
    nacionalidade: "Vaticano",
  },
  {
    país: "Venezuela",
    nacionalidade: "Venezuelano",
  },
  {
    país: "Vietnã",
    nacionalidade: "Vietnamita",
  },
  {
    país: "Zâmbia",
    nacionalidade: "Zambiano",
  },
  {
    país: "Zimbábue",
    nacionalidade: "Zimbabuense",
  },
];

export default nacionalidades;
