import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as authActions from "store/auth/actions";
import Header from "components/header/Header";
import CardExplainContainer from "components/cardExplainContainer/CardExplainContainer";
import BenefitsContainer from "components/benefitsContainer/BenefitsContainer";
import MedicalAppointments from "components/medicalAppointmentsContainer/medicalAppointmentsContainer";
import OursPackagesContainer from "components/oursPackagesContainer/oursPackagesContainer";
import SaveMoneyContainer from "components/saveMoneyContainer/saveMoneyContainer";
import AdvantagesContainer from "components/advantagesContainer/advantagesContainer";
import PartnersContainer from "components/partnersContainer/partnersContainer";
import HowItWorksContainer from "components/howItWorksContainer/howItWorksContainer";
import DifferentialsContainer from "components/differentialsContainer/differentialsContainer";
import ContactContainer from "components/contactContainer/contactContainer";
import Footer from "components/footer/footer";
import * as packagesActions from "store/packages/actions";

export const Home = ({ authRequest, verifyTokenRequest, auth_token, vendorsRequest }) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      vendorsRequest()

      if (auth_token) {
        verifyTokenRequest();
      } else {
        authRequest();
      }
    }
  }, [authRequest, auth_token, verifyTokenRequest, vendorsRequest]);

  return (
    <>
      <Header />

      <main>
        <CardExplainContainer />
        <BenefitsContainer />
        <MedicalAppointments />
        <AdvantagesContainer />
        <PartnersContainer />
        <SaveMoneyContainer />
        <OursPackagesContainer />
        <HowItWorksContainer />
        <DifferentialsContainer />
        <ContactContainer />
        <Footer />
        <div className="overlay-logo" />
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.data?.access_token,
    auth_state: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vendorsRequest: () => {
      dispatch(packagesActions.vendorsRequest());
    },
    authRequest: () => {
      dispatch(authActions.authRequest());
    },
    verifyTokenRequest: () => {
      dispatch(authActions.verifyTokenRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
