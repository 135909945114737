export const saveStateSessionStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch {
    console.log("error on save state on session storage");
  }
};

export const loadStateSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const slugfy = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const replaceOnlyNumber = (text) => {
  return text.match(/\d+/g).pop();
};

export const getWhatsByVendorId = (vendorCpf, arrayVendors) => {
  let vendor = arrayVendors.find((x) => x.cpf === vendorCpf);
  let defaultNumber = "5511916235625"

  return vendor?.mobile_number || defaultNumber;
};

export const getVendor = (vendorCpf, arrayVendors) => {
  let vendor = arrayVendors.find((x) => x.cpf === vendorCpf);

  return vendor || null;
};
