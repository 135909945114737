import React from "react";
import { MainContainer } from "components/container/MainContainer";
import cartaoNacaoLogo from "assets/img/cartao-nacao-logo.png";
import {
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <MainContainer>
        <div>
          <img src={cartaoNacaoLogo} alt="Logo Cartão Nação Saúde" />
        </div>

        <div className="redes-sociais">
          <a
            href="https://www.facebook.com/cartaonacaosaude"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} size={"25px"} color={"#ccc"} />
          </a>

          <a
            href="https://www.instagram.com/cartaonacaosaude/?hl=pt-br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} size={"25px"} color={"#ccc"} />
          </a>
        </div>

        <span>2023 © Nação Saúde. All rights reserved.</span>
        <span>
        <a href="mailto:relacionamento@cartaonacaosaude.com.br" style={{ color: '#ccc', textDecoration: 'none' }}>relacionamento@cartaonacaosaude.com.br</a>

        </span>
      </MainContainer>
    </div>
  );
};

export default Footer;
