export const packagesTypes = {
    PACKAGES_REQUEST: "./packages/PACKAGES_REQUEST",
    PACKAGES_SUCCESS: "./packages/PACKAGES_SUCCESS",
    PACKAGES_FAILURE: "./packages/PACKAGES_FAILURE",
    REGISTER_REQUEST: "./packages/REGISTER_REQUEST",
    REGISTER_SUCCESS: "./packages/REGISTER_SUCCESS",
    REGISTER_FAILURE: "./packages/REGISTER_FAILURE",
    REGISTER_RESET: "./packages/REGISTER_RESET",
    VENDORS_REQUEST: "./packages/VENDORS_REQUEST",
    VENDORS_SUCCESS: "./packages/VENDORS_SUCCESS",
    VENDORS_FAILURE: "./packages/VENDORS_FAILURE",
}