import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { MainContainer } from "components/container/MainContainer";
import MaskedInput from "react-text-mask";
import "./dependentsForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import nacionalidades from "common/nacionalidades";

export const DependentsForm = ({ setDependentsRegister }) => {
  const MAXIMUM_DEPENDENTS = 3;

  const [dependents, setDependents] = useState([
    {
      name: "",
      email: "",
      gender: "",
      birth_date: "",
      mobile_number: "",
      cpf: "",
      rg: "",
      nationality: "",
    },
  ]);

  const addDependents = () => {
    setDependents([
      ...dependents,
      {
        name: "",
        email: "",
        gender: "",
        birth_date: "",
        mobile_number: "",
        cpf: "",
        rg: "",
        nationality: "",
      },
    ]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newDependents = [...dependents];

    if (event.target.name === "rg") {
      newDependents[index][name] = value.toUpperCase();
    } else {
      newDependents[index][name] = value;
    }

    setDependents(newDependents);
    setDependentsRegister(newDependents);
  };

  const removeDependent = (index) => {
    const newDependents = [...dependents];
    newDependents.splice(index, 1);
    setDependents(newDependents);
    setDependentsRegister(newDependents);
  };

  return (
    <div>
      <button
        onClick={addDependents}
        disabled={dependents.length >= MAXIMUM_DEPENDENTS ? true : false}
        variant="primary"
        className="btn-green"
      >
        Adicionar mais dependentes
      </button>

      {dependents.map((dependent, index) => (
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "white",
            marginTop: "20px",
            padding: "20px",
            display: "flex",
            alignItems: "center",
          }}
          key={`dependent-${index}`}
        >
          <MainContainer>
            <Row className="mb-3">
              <Form.Group className="col-12 col-sm-8 pb-2" as={Col}>
                <Form.Control
                  onChange={(event) => handleChange(index, event)}
                  placeholder="Nome do Dependente"
                  name="name"
                  required
                />
                <Form.Text className="text-muted">
                  Nome completo do dependente
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={`birth_date-${index}`}>
                <Form.Control
                  type="date"
                  onChange={(event) => handleChange(index, event)}
                  name="birth_date"
                  required
                />
                <Form.Text className="text-muted">
                  Data de Nascimento (dd/mm/aaaa)
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3 text-center text-sm-start" required>
                <Form.Check
                  inline
                  type="radio"
                  label="Masculino"
                  value="M"
                  name="gender"
                  onChange={(event) => handleChange(index, event)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Feminino"
                  value="F"
                  name="gender"
                  onChange={(event) => handleChange(index, event)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                className="mb-3 col-12 col-sm-6"
                controlId={`email-${index}`}
              >
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(event) => handleChange(index, event)}
                  name="email"
                  required
                />
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-3 col-12 col-sm-6"
                controlId={`mobile-${index}`}
              >
                <Form.Control
                  type="tel"
                  pattern="[0-9]{11}|[0-9]{12}"
                  placeholder="Whatsapp (DDD + Número)"
                  onChange={(event) => handleChange(index, event)}
                  name="mobile_number"
                  required
                />
                <Form.Text className="text-muted">
                  Somente dígitos - Exemplo: 11910101111
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                className="mb-3 col-6 col-sm-4"
                controlId={`cpf-${index}`}
              >
                <MaskedInput
                  placeholder="CPF"
                  className="form-control"
                  name="cpf"
                  required
                  minLength={11}
                  onChange={(event) => handleChange(index, event)}
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-3 col-6 col-sm-4"
                controlId={`rg-${index}`}
              >
                <MaskedInput
                  placeholder="RG"
                  className="form-control"
                  name="rg"
                  required
                  value={dependents[index].rg}
                  onChange={(event) => handleChange(index, event)}
                  mask={[
                    /[0-9]/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /[a-zA-Z0-9]/,
                  ]}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-3 col-12 col-sm-4"
                controlId="nationality"
              >
                <Form.Control
                  as="select"
                  name="nationality"
                  value={dependents[index].nationality}
                  className="form-select"
                  onChange={(event) => handleChange(index, event)}
                  required
                >
                  <option value="">Selecione sua nacionalidade</option>
                  {nacionalidades.map((nac, i) => (
                    <option key={i} value={nac.nacionalidade}>
                      {nac.nacionalidade}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>

            <Row>
              <Col className="text-center text-sm-end py-2">
                <button
                  variant="primary"
                  className="btn-red"
                  onClick={() => {
                    removeDependent(index);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> {/* Ícone de lixeira */}
                </button>
              </Col>
            </Row>
          </MainContainer>
        </div>
      ))}
    </div>
  );
};

export default DependentsForm;
