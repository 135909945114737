import React, { useState, useMemo } from "react";
import { MainContainer } from "components/container/MainContainer";
import handPhone from "assets/img/hand-phone.png";
import btnWhats from "assets/img/btn-whats.png";
import ReactWhatsapp from "react-whatsapp";
import { replaceOnlyNumber, getWhatsByVendorId } from "common/utils";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import "./contactContainer.scss";

export const ContactContainer = ({ vendors }) => {
  const [vendorId, setVendorId] = useState("");
  const { search } = useLocation();

  useMemo(() => {
    if (search) {
      setVendorId(replaceOnlyNumber(search));
    }
  }, [search]);

  return (
    <div className="contato">
      <MainContainer>
        <div className="text">
          <p className="f-title">
            {/* Chega de desculpas <br />
            <span>para não se cuidar!</span> */}
            Comece hoje a se cuidar por uma mensalidade <span>que cabe no seu bolso!</span>

          </p>
          <p className="f-subtitle">
            <b>Assine agora mesmo nosso cartão</b> e dê prioridade para sua
            saúde com uma solução acessível, confiável, sem burocracias e sem
            carência.
          </p>

          <div className="btn-whats">
            <img src={btnWhats} alt="Botão Whatsapp" />
            {!!vendors.length && (
              <ReactWhatsapp
                number={`${getWhatsByVendorId(vendorId, vendors)}`}
                message="Oi, estou precisando de atendimento"
              >
                Entre em contato agora
              </ReactWhatsapp>
            )}
          </div>

          <div>
            <span className="mini-font">
              *Cartão de desconto não é plano de saúde, não garante e não se
              responsabiliza pelos serviços oferecidos e pelo pagamento das
              despesas, nem assegura desconto em todos os serviços
              obrigatoriamente garantidos por plano de saúde. Tudo o que o
              cliente usar ou comprar será por ele pago ao prestador,
              assegurando-se apenas os preços e descontos que constam na relação
              de empresas e serviços conveniados disponibilizados neste site. O
              atendimento será em até 15 dias, mediante disponibilidade na
              clínica. Consulte a clínica parceira para as especialidades
              disponíveis e condições para marcar as consultas.
            </span>
          </div>
        </div>

        <div className="image-container">
          <img src={handPhone} alt="App Cartão Nação Saúde" />
        </div>
      </MainContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendors: state.packages.vendors.users,
  };
};

export default connect(mapStateToProps)(ContactContainer);
