import { useEffect } from 'react';

const GerencianetScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    const v = parseInt(Math.random() * 1000000);

    // script.src = `https://sandbox.gerencianet.com.br/v1/cdn/02b7d6342f10a211303780376b63eb02/${v}`;
    // script.async = false;
    // script.id = '02b7d6342f10a211303780376b63eb02';

    script.src = `https://api.gerencianet.com.br/v1/cdn/02b7d6342f10a211303780376b63eb02/${v}`;
    script.async = false;
    script.id = '02b7d6342f10a211303780376b63eb02';

    if (!document.getElementById('02b7d6342f10a211303780376b63eb02')) {
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    window.$gn = {
      validForm: true,
      processed: false,
      done: {},
      ready: function (fn) {
        window.$gn.done = fn;
      }
    };

    script.onload = function () {
      window.$gn.ready(function () {
        console.log('Efi Script Loaded');
      });
    };

  }, []);

  return null;
}

export default GerencianetScript;



