import React, { useState, useMemo } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import logo from "assets/img/logo-cartao-saude.png";
import ReactWhatsapp from "react-whatsapp";
import { replaceOnlyNumber, getWhatsByVendorId } from "common/utils";
import { connect } from "react-redux";
import "./header.scss";

export const Header = ({ vendors }) => {
  const [vendorId, setVendorId] = useState("");
  const { search } = useLocation();

  useMemo(() => {
    if (search) {
      setVendorId(replaceOnlyNumber(search));
    }
  }, [search]);

  return (
    <>
      <Navbar
        expand="lg"
        className="shadow-sm p-0 navbar navbar-expand-lg navbar-light d-flex flex-column"
      >
        <Container className="p-4">
          <Navbar.Brand href="#">
            <img src={logo} className="img-fluid" alt="logo cartão saúde" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end text-center"
          >
            <Nav defaultActiveKey="/" className="align-items-center">
              <Nav.Link href="#pacotes">Nossos pacotes</Nav.Link>
              <Nav.Link href="#" as="a">
                {!!vendors.length && (
                  <ReactWhatsapp
                    number={`${getWhatsByVendorId(vendorId, vendors)}`}
                    message="Oi, estou precisando de atendimento"
                    className="btn-whats"
                  >
                    Entre em contato agora
                  </ReactWhatsapp>
                )}
              </Nav.Link>

              <Nav.Link href="#pacotes" as="a" className="btn-order-now">
                Peça já o seu
              </Nav.Link>

              <Nav.Link
                href="https://nacaosaude.centraldeatendimento.me/login"
                as="a"
                className="btn-green"
                target="_blank"
                rel="noopener noreferrer"
              >
                Meu Acesso
              </Nav.Link>

              <Nav.Link
                href="https://nacaosaude.clubeparcerias.com.br/member/login"
                as="a"
                className="btn-green"
                target="_blank"
                rel="noopener noreferrer"
              >
                Meu Clube
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    vendors: state.packages.vendors.users,
  };
};

export default connect(mapStateToProps)(Header);
