import React, { memo } from "react";
import "./benefitsContainer.scss";

export const GridComponent = ({ icon, name, children }) => {
  return (
    <div className="grid-component">
      <div className="pb-4">
        <img src={icon} alt={name} />
      </div>

      <div className="message">{children}</div>
    </div>
  );
};

export default memo(GridComponent);
