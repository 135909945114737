import React from "react";
import "./saveMoneyContainer.scss";

export const tableSaveEconomyComponent = ({ title, children, className }) => {
  return (
    <div className={`table ${className ? className : ""}`}>
      {title}
      {children}
    </div>
  );
};

export default tableSaveEconomyComponent;
