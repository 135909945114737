import React from "react";
import { MainContainer } from "components/container/MainContainer";
import masterCard from "assets/img/master.svg";
import hipercard from "assets/img/hipercard.svg";
import elo from "assets/img/elo.svg";
import diners from "assets/img/diners.svg";
import amex from "assets/img/amex.svg";
import visa from "assets/img/visa.svg";
import "./creditCardFlagContainer.scss";

export const CreditCardFlagContainer = () => {
  return (
    <div className="payments-conditions">
      <MainContainer className="">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="col d-flex justify-content-md-between justify-content-center flex-sm-wrap flex-wrap">
              <img className="p-2" src={masterCard} alt="Master Card Logo" />
              <img className="p-2" src={visa} alt="Visa Logo" />
              <img className="p-2" src={amex} alt="America Express Logo" />
              <img className="p-2" src={diners} alt="Diners Logo" />
              <img className="p-2" src={elo} alt="Elo Logo" />
              <img className="p-2" src={hipercard} alt="Hipercard Logo" />
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default CreditCardFlagContainer;
