import React, { useEffect, useMemo, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { faCreditCard, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContainer } from "components/container/MainContainer";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/img/logo-cartao-saude.png";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import * as packagesActions from "store/packages/actions";
import contrato from "assets/docs/Contrato-de-Servicos-Cartao-Nacao-Saude-e-Beneficios-2022.pdf";
import PaymentMethod from "components/paymentMethodContainer/paymentMethod";
import CreditCardForm from "components/paymentMethodContainer/creditCardForm";
import GerencianetScript from "components/paymentMethodContainer/gerencianetScript";
import CreditCardFlagContainer from "components/creditCardFlagContainer/creditCardFlagContainer";
import DependentsForm from "components/dependentsForm/dependentsForm";
import nacionalidades from "common/nacionalidades";

import loading from "../../assets/img/new/loading-circle.gif";
import "./register.scss";

export const Register = ({
  registerRequest,
  registerState,
  registerReset,
  routePackageId,
}) => {
  const { pathname, state: packageInfo} = useLocation();
  const [path, setPath] = useState("");
  const [packageId, setPackageId] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [dependents, setDependents] = useState([]);
  const [isLoadingCep, setIsLoadingCep] = useState(false);
  const navigate = useNavigate();

  useMemo(() => {
    setPath(pathname.split("/")[1]);
  }, [pathname]);

  useEffect(() => {
    return () => {
      registerReset();
    };
  }, [registerReset]);

  useEffect(() => {
    if (packageInfo?.packageId !== null) {
      setPackageId(packageInfo?.packageId);
    }
  }, [packageInfo]);

  useEffect(() => {
    if (routePackageId !== null) {
      setPackageId(routePackageId);
    }
  }, [routePackageId]);

  useEffect(() => {
    if (paymentMethod === "1") {
      //banking_billet
      setPaymentToken("");
    }
  }, [paymentMethod]);

  const [data, setData] = useState({
    package_id: 0,
    payment_type: "",
    payment_token: "",
    vendor_cpf: "",
    vendor_user_id: "",
    name: "",
    email: "",
    gender: "",
    birth_date: "",
    mobile_number: "",
    cpf: "",
    rg: "",
    nationality: "",
    address: "",
    address_number: "",
    address_complement: "",
    district: "",
    city: "",
    state: "",
    zip_code: "",
  });

  const [radio, setRadio] = useState({
    majority: false,
    terms: false,
    notifications: false,
  });

  const replaceSpecialCharacter = (text) => {
    return text.replace(/[^\w\s]|_/gi, "");
  };

  const handleGetCreditCardToken = (token) => {
    setPaymentToken(token.data.payment_token);
  };

  const getAddressByCep = async (cep) => {
    setIsLoadingCep(true);

    let sanitizedCep = cep.replace("-", "");

    await fetch(`https://viacep.com.br/ws/${sanitizedCep}/json`)
      .then((res) => {
        setTimeout(async () => {
          let result = await res.json();

          if (result.erro) {
            setData((current) => ({
              ...current,
              city: "",
              address: "",
              district: "",
              state: "",
            }));
          } else {
            setData((current) => ({
              ...current,
              city: result.localidade,
              address: result.logradouro,
              district: result.bairro,
              state: result.uf,
              zip_code: result.cep,
            }));
          }

          setIsLoadingCep(false);
        }, 700);
      })
      .catch(() => {
        setIsLoadingCep(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let newData = {
      ...data,
      payment_type: paymentMethod,
      payment_token: paymentToken,
      package_id: packageId,
      vendor_cpf: packageInfo.vendor_cpf,
      vendor_user_id: packageInfo.vendor_user_id,
      cpf: replaceSpecialCharacter(data.cpf),
      rg: replaceSpecialCharacter(data.rg),
      zip_code: replaceSpecialCharacter(data.zip_code),
    };

    if (newData.vendor_cpf === "") {
      delete newData.vendor_cpf;
      delete newData.vendor_user_id;
    }

    let newDataDependents = [];
    if (dependents.length > 0) {
      dependents.forEach((item, index) => {
        let dependent = item;
        dependent.cpf = replaceSpecialCharacter(dependent.cpf);
        dependent.parent_cpf = newData.cpf;
        dependent.address = newData.address;
        dependent.address_number = newData.address_number;
        dependent.address_complement = newData.address_complement;
        dependent.district = newData.district;
        dependent.city = newData.city;
        dependent.state = newData.state;
        dependent.zip_code = newData.zip_code;
        newDataDependents.push(dependent);
      });
      newData.dependents = newDataDependents;
    }

    registerRequest(newData, navigate)
  };

  return (
    <div className="register">
      <GerencianetScript />
      <div className="col text-center py-4 title">
        <img src={logo} className="img-fluid" alt="logo cartão saúde" />
        <h2 className="p-2">
          Cadastro <span>{path}</span>
        </h2>
      </div>

      <MainContainer>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            {packageInfo?.vendor_name && (
              <Form.Group
                className="col-12 col-sm-3 pb-2"
                as={Col}
                controlId="name"
              >
                <Form.Control
                  value={packageInfo.vendor_name}
                  disabled
                  placeholder="Nome do Titular"
                  required
                />
                <Form.Text className="text-muted">
                  CPF ou Nome do Vendedor
                </Form.Text>
              </Form.Group>
            )}

            <Form.Group
              className="col-12 col-sm-7 pb-2"
              as={Col}
              controlId="name"
            >
              <Form.Control
                onChange={(e) => setData({ ...data, name: e.target.value })}
                placeholder="Nome do Titular"
                required
              />
              <Form.Text className="text-muted">
                Nome completo do Titular
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} controlId="birth_date">
              <Form.Control
                type="date"
                onChange={(e) =>
                  setData({ ...data, birth_date: e.target.value })
                }
                required
              />
              <Form.Text className="text-muted">
                Data de Nascimento (dd/mm/aaaa)
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="mb-3 text-center text-sm-start" required>
              <Form.Check
                inline
                type="radio"
                label="Masculino"
                value="M"
                name="radioGender"
                onChange={(e) => setData({ ...data, gender: e.target.value })}
              />
              <Form.Check
                inline
                type="radio"
                label="Feminino"
                value="F"
                name="radioGender"
                onChange={(e) => setData({ ...data, gender: e.target.value })}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-6"
              controlId="email"
            >
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-6"
              controlId="mobile_number"
            >
              <Form.Control
                type="tel"
                pattern="[0-9]{11}|[0-9]{12}"
                placeholder="Whatsapp (DDD + Número)"
                onChange={(e) =>
                  setData({ ...data, mobile_number: e.target.value })
                }
                required
              />
              <Form.Text className="text-muted">
                Somente dígitos - Exemplo: 11910101111
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-3 col-6 col-sm-4"
              controlId="cpf"
            >
              <MaskedInput
                placeholder="CPF"
                className="form-control"
                required
                minLength={11}
                onChange={(e) => setData({ ...data, cpf: e.target.value })}
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3 col-6 col-sm-4" controlId="rg">
              <MaskedInput
                placeholder="RG"
                className="form-control"
                required
                value={data.rg}
                onChange={(e) => {
                  setData({ ...data, rg: e.target.value.toUpperCase() });
                }}
                mask={[
                  /[0-9]/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /[a-zA-Z0-9]/,
                ]}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-4"
              controlId="nationality"
            >
              <Form.Control
                as="select"
                value={data.nationality}
                className="form-select"
                onChange={(e) =>
                  setData({ ...data, nationality: e.target.value })
                }
                required
              >
                <option value="">Selecione sua nacionalidade</option>
                {nacionalidades.map((nac, i) => (
                  <option key={i} value={nac.nacionalidade}>
                    {nac.nacionalidade}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-3 input-cep"
              controlId="zip_code"
            >
              <MaskedInput
                placeholder="CEP"
                className="form-control"
                onChange={(e) => {
                  const cepRegex = /^\d{5}-\d{3}$/;

                  if (cepRegex.test(e.target.value)) {
                    getAddressByCep(e.target.value);
                  } else {
                    setData({ ...data, zip_code: e.target.value });
                  }
                }}
                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                required
              />

              <span
                className={`container-loading ${
                  isLoadingCep ? "loading-active" : ""
                }`}
              >
                <img src={loading} alt="Carregando..." />
              </span>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-8"
              controlId="address"
            >
              <Form.Control
                value={data.address}
                required
                placeholder="Endereço (Avenida, Rua...)"
                onChange={(e) => setData({ ...data, address: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-2"
              controlId="address_number"
            >
              <Form.Control
                placeholder="Número"
                required
                onChange={(e) =>
                  setData({ ...data, address_number: e.target.value })
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-3"
              controlId="district"
            >
              <Form.Control
                value={data.district}
                placeholder="Bairro / Jardim"
                onChange={(e) => setData({ ...data, district: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-3"
              controlId="city"
            >
              <Form.Control
                placeholder="Cidade"
                value={data.city}
                onChange={(e) => setData({ ...data, city: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-3"
              controlId="state"
            >
              <Form.Control
                as="select"
                value={data.state}
                className="form-select"
                onChange={(e) => setData({ ...data, state: e.target.value })}
                required
              >
                <option value="">Selecione o estado</option>
                <option value="SP">SP</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-3 col-12 col-sm-3"
              controlId="address_complement"
            >
              <Form.Control
                placeholder="Complemento (fundos, apto, bloco)"
                onChange={(e) =>
                  setData({ ...data, address_complement: e.target.value })
                }
              />
            </Form.Group>
          </Row>

          {path === "familiar" || path === "familiar-plus" ? (
            <Form.Group className="mb-3">
              <hr></hr>
              <Form.Label>
                <strong>Dependentes</strong>
              </Form.Label>
              <div className="d-flex justify-content-start">
                <p>
                  Nos Pacotes <strong>Familiar</strong> e{" "}
                  <strong>Familiar Plus</strong>. você poderá cadastrar até 3
                  dependentes.<br></br>
                  Para adicionar mais dependentes, clique em adicionar
                  dependentes.
                </p>
              </div>
              <DependentsForm setDependentsRegister={setDependents} />
            </Form.Group>
          ) : (
            ""
          )}

          <hr></hr>

          <Form.Group className="mb-3">
            <PaymentMethod
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
            <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {paymentMethod === "2" ? ( //credit_card
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                      />
                      <p style={{ margin: 0 }}>Cartão de crédito</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginTop: "10px" }}>
                        Ao optar pelo pagamento via cartão de crédito, a
                        primeira mensalidade será debitada imediatamente em seu
                        cartão, já com a composição da taxa de adesão e
                        mensalidade referente ao benefício escolhido. Nos meses
                        seguintes, a mensalidade será automaticamente debitada
                        em seu cartão de crédito, sempre na data de vencimento
                        previamente estabelecida. É uma forma de pagamento
                        segura e prática, que garante a renovação automática do
                        seu benefício de saúde.
                      </p>
                    </div>
                    <CreditCardFlagContainer />
                    <CreditCardForm
                      onGetCreditCardToken={handleGetCreditCardToken}
                    />
                  </div>
                ) : paymentMethod === "1" ? ( //banking_billet
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon
                        icon={faBarcode}
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                      />
                      <p style={{ margin: 0 }}>Boleto</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginTop: "10px" }}>
                        Ao optar pelo pagamento via boleto, você receberá em seu
                        e-mail a fatura com a composição da primeira
                        mensalidade, que é composta pela taxa de adesão mais a
                        mensalidade referente ao benefício escolhido. O boleto
                        bancário tem vencimento em até 3 dias úteis após a
                        emissão e pode ser pago em qualquer banco, lotérica ou
                        pela internet banking. Após o pagamento, o seu benefício
                        será ativado em até 48 horas úteis.
                      </p>
                    </div>
                  </div>
                ) : (
                  <p style={{ margin: 0 }}>Selecione uma opção de pagamento</p>
                )}
              </div>
            </Row>
          </Form.Group>

          <hr />

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Maior de 18 anos"
              onChange={(e) => {
                setRadio({ ...radio, majority: e.target.checked });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              onChange={(e) => {
                setRadio({ ...radio, terms: e.target.checked });
              }}
              label={
                <span>
                  Li e aceito os termos e condições.
                  <a
                    className="px-1"
                    href={contrato}
                    download="Contrato-de-Servicos-Cartao-Nacao-Saude-e-Beneficios-2022"
                  >
                    Baixar contrato
                  </a>
                </span>
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              onChange={(e) => {
                setRadio({ ...radio, notifications: e.target.checked });
              }}
              label="Eu aceito notificações e contato via SMS, e-mail ou telefone"
            />
          </Form.Group>

          <Row>
            <Col className="text-center text-sm-end py-4">
              <button
                disabled={
                  registerState.loading || !radio.majority || !radio.terms
                }
                variant="primary"
                className="btn-green"
                type="submit"
              >
                {registerState.loading ? "Enviando..." : "Enviar"}
              </button>
            </Col>
          </Row>
        </Form>
      </MainContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registerState: state.packages.register,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerRequest: (data, navigate) => {
      dispatch(packagesActions.registerRequest(data, navigate));
    },
    registerReset: () => {
      dispatch(packagesActions.registerReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
