import { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import luhn from "luhn";
import "./creditCardForm.scss";

const CreditCardForm = ({onGetCreditCardToken}) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    cardBrand: "",
    cardNumber: "",
    cardHolderName: "",
    expiryDate: "",
    cvv: ""
  });

  const sucessMessage = (<p className="text-success mt-3">Cartão de crédito valido!<br></br>Aceite os termos abaixo e clique em enviar!</p>)
  const errorMessage = (  <p className="text-danger mt-3">Cartão de crédito inválido!</p>)
  
  const handleButtonCheckClick = (event) => {
    event.preventDefault();

    if (!data.cardNumber) {
      setIsValid(false);
      setMessage(errorMessage);
      return
    }

    if (!luhn.validate(data.cardNumber)) {
      setIsValid(false);
      setMessage(errorMessage);
      return
    }

    //Tokenizar GN
    return handleCardToken()

  };

  const handleCardToken = () => {
    setIsLoading(true)
    window.$gn.checkout.getPaymentToken(
      {
        brand: data.cardBrand,
        number: data.cardNumber,
        cvv: data.cvv,
        expiration_month: data.expiryDate.substring(0, 2),
        expiration_year: data.expiryDate.substring(3),
        reuse: true
      },
      function (error, response) {
        if (error) {
          setIsLoading(false)
          setIsValid(false)
          setMessage(errorMessage);    
          console.error(error);
        } else {
          setIsLoading(false)
          setIsValid(false)
          setMessage(sucessMessage);    
          console.log(isValid);
          onGetCreditCardToken(response)
          
        }
      }
    );
  }

  return (
    <div>
      <Form.Group className="col-md-3" controlId="cardBrand">
        <Form.Label>Bandeira do cartão</Form.Label>
        <Form.Control as="select" value={data.cardBrand} className="form-select" onChange={(e) => setData({ ...data, cardBrand: e.target.value })} required>
          <option value="">Selecione a bandeira</option>
          <option value="visa">Visa</option>
          <option value="mastercard">MasterCard</option>
          <option value="amex">Amex</option>
          <option value="diners">Diners</option>
          <option value="elo">Elo</option>
          <option value="hipercard">Hipercard</option>
        </Form.Control>
      </Form.Group>

      <div style={{ padding: '5px' }} />

      <Form.Group controlId="formCardNumber" className="col-md-3">
        <Form.Label>Número do cartão</Form.Label>
        <MaskedInput
          placeholder="Número do cartão"
          className="form-control"
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={data.cardNumber}
          onChange={(e) => setData({ ...data, cardNumber: e.target.value })}
          required
        />
      </Form.Group>

      <div style={{ padding: '5px' }} />

      <Form.Group controlId="formCardHolderName" className="col-md-3">
        <Form.Label>Nome do titular do cartão</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nome completo"
          value={data.cardHolderName}
          onChange={(e) => setData({ ...data, cardHolderName: e.target.value })}
          required
        />
      </Form.Group>

      <div style={{ padding: '5px' }} />

      <Form.Group controlId="formExpiryDate" className="col-md-2">
        <Form.Label>Data de validade</Form.Label>
        <MaskedInput
          className="form-control"
          mask={[
            /[0-9]/,
            /[0-9]/,
            "/",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/
          ]}
          placeholder="MM/AA"
          value={data.expiryDate}
          onChange={(e) => setData({ ...data, expiryDate: e.target.value })}
          required
        />
      </Form.Group>

      <div style={{ padding: '5px' }} />

      <Form.Group controlId="formCvv" className="col-md-2">
        <Form.Label>Código de segurança</Form.Label>
        <MaskedInput
          className="form-control"
          mask={[
            /[0-9]/,
            /[0-9]/,
            /[0-9]/
          ]}
          placeholder="___"
          value={data.cvv}
          onChange={(e) => setData({ ...data, cvv: e.target.value })}
          required
        />
      </Form.Group>

      <div style={{ padding: '10px' }} />

      <Row>
        <Col className="text-left">
          <Button
            disabled={isLoading}
            className='btn-green'
            type="button"
            onClick={handleButtonCheckClick}
          >
            {isLoading ? "Validando..." : "Validar Cartão"}

          </Button>
        </Col>
      </Row>

      

     <>
     {message}
     </>
    </div>


  );
}

export default CreditCardForm;