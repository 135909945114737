import { Routes, Route, useLocation, Navigate  } from "react-router-dom";
import Home from "pages/Home/Home";
import Register from "pages/Register/Register";
import Toast from "components/toast/Toast";
import { useState, useEffect } from "react";
import Resume from "pages/Resume/Resume";

const Routers = () => {
  const [packageId, setPackageId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/individual") {
      setPackageId(1);
    } else if (location.pathname === "/individual-plus") {
      setPackageId(2);
    } else if (location.pathname === "/familiar") {
      setPackageId(3);
    } else if (location.pathname === "/familiar-plus") {
      setPackageId(4);
    } else {
      setPackageId(null);
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/individual"} element={<Register routePackageId={packageId} />} />
        <Route path={"/individual-plus"} element={<Register routePackageId={packageId} />} />
        <Route path={"/familiar"} element={<Register routePackageId={packageId} />} />
        <Route path={"/familiar-plus"} element={<Register routePackageId={packageId} />} />
        <Route path={"/resumo"} element={<Resume />} />
        <Route path={"/*"} element={<Navigate to="/" />} />
      </Routes>
      <Toast />
    </>
  );
};

export default Routers;
