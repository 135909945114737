import React from "react";
import { MainContainer } from "components/container/MainContainer";
import { Carousel } from "react-bootstrap";

import logoCruzeiro from "assets/img/cruzeiro-do-sul.png";

import logoPagueMenos from "assets/img/pague-menos-logo.png";
import logoDrogasil from "assets/img/drogasil-logo.png";
import logoPacheco from "assets/img/drogarias-pacheco-logo.png";
import logoDrogariaSP from "assets/img/logo-drogaria-sao-paulo.png";
import logoDrogariaRaia from "assets/img/droga-raia-logo.png";
import logoExtraFarma from "assets/img/extrafarma-logo.png";

import logoEinstein from "assets/img/einstein-conecta.jpg";
import logoFleury from "assets/img/fleury.png";
import logoLavoisier from "assets/img/lavoisier.png";
import logoHermesPardini from "assets/img/hermes-pardini.png";
import logoBronstein from "assets/img/bronstein.png";
import logoPinotti from "assets/img/pinotti.png";
import logoDasa from "assets/img/dasa.png";

import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import portoSeguroLogo from "assets/img/porto-seguro-logo-black.png";
import "./partnersContainer.scss";

export const PartnersContainer = () => {

  return (
    <div className="parceiros">
      <MainContainer>
        <div className="text">
          <h3 className="pb-2">P A R C E I R O S</h3>

          <p className="f-title">
            Descubra alguns dos <b>profissionais</b> e <b>estabelecimentos</b>{" "}
            conveniados
          </p>

          <p className="f-subtitle">
            Asseguramos uma extensa rede de alta qualidade. Com inúmeras opções
            em todo o território nacional.
          </p>

          <div className="text-center text-sm-start desktop">
            <a href="#pacotes" className="btn-green">
              Assine agora
            </a>
          </div>
        </div>

        <div>
          <Carousel
            interval={null}
            indicators={false}
            prevIcon={
              <FontAwesomeIcon
                icon={faCircleChevronLeft}
                size={"2x"}
                color={"#30b23f"}
                className="ps-2 pt-1"
              />
            }
            nextIcon={
              <FontAwesomeIcon
                icon={faCircleChevronRight}
                size={"2x"}
                color={"#30b23f"}
                className="ps-2 pt-1"
              />
            }
          >
            <Carousel.Item>
              <p className="blue-title">DROGARIAS</p>
              <div className="image-container">
                <img src={logoPagueMenos} alt="Logo Pague Menos" />
                <img src={logoDrogasil} alt="Logo Drogasil" />
                <img src={logoPacheco} alt="Logo Pacheco" />
                <img src={logoDrogariaSP} alt="Logo Drogaria São Paulo" />
                <img src={logoDrogariaRaia} alt="Logo Drogaria Raia" />
                <img src={logoExtraFarma} alt="Logo Extra Farma" />
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <p className="blue-title">EDUCAÇÃO</p>
              <div className="image-container">
                <img src={logoCruzeiro} alt="Logo Cruzeiro do Sul" />
              </div>
              <p className="blue-title">SEGUROS</p>
              <div className="image-container">
                <img src={portoSeguroLogo} alt="Logo Hospital Einstein" />
              </div>
            </Carousel.Item>

            <Carousel.Item className="saude">
              <p className="blue-title">SAÚDE</p>
              <div className="image-container">
                <img src={logoEinstein} alt="Logo Einstein Conecta" />
                <img src={logoFleury} alt="Logo Fleury" />
                <img src={logoLavoisier} alt="Logo Lavoisier" />
                <img src={logoHermesPardini} alt="Logo Hermes Pardini" />
                <img src={logoBronstein} alt="Logo Bronstein" />
                <img src={logoPinotti} alt="Logo Pinotti" className="pinotti" />
                <img src={logoDasa} alt="Logo Dasa" />
                <div className="muito-mais">E muito +</div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="text-center text-sm-start mobile">
          <a className="btn-green" href="#pacotes">
            Assine agora
          </a>
        </div>
      </MainContainer>
    </div>
  );
};

export default PartnersContainer;
