import React from "react";
import { Form } from "react-bootstrap";

const PaymentMethod = ({ paymentMethod, setPaymentMethod }) => {
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <Form.Group controlId="payment_method" className="mb-3" required>
      <Form.Label><strong>Forma de Pagamento</strong></Form.Label>      
      <div className="d-flex justify-content-start">
        <Form.Check
          type="radio"
          id="boleto"
          name="payment_method"
          label="Boleto"
          value="1"
          checked={paymentMethod === "1"} //banking_billet
          onChange={handlePaymentMethodChange}
          className="me-3"
        />
        <Form.Check
          type="radio"
          id="credit-card"
          name="payment_method"
          label="Cartão de Crédito"
          value="2"
          checked={paymentMethod === "2"} //credit_card
          onChange={handlePaymentMethodChange}
        />
      </div>
    </Form.Group>
  );
};

export default PaymentMethod;
