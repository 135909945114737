import { call, put, retry } from "redux-saga/effects";
import { Api } from "common/api";

import {
  packagesSuccess,
  packagesFailure,
  registerSuccess,
  registerFailure,
  registerReset,
  registerRequest as retryRegister,
  vendorsSuccess,
  vendorsFailure,
} from "./actions";
import { authRequest } from "../auth/actions";
import { showToastr } from "store/toast/actions";

export function* packagesRequest() {
  try {
    const { data } = yield call(Api.get, "/packages");
    yield put(packagesSuccess(data));
  } catch (error) {
    yield put(packagesFailure(error));
  }
}

export function* registerRequest(action) {
  try {
    const { data } = yield call(Api.post, "/customer", action.payload.data);
    yield put(registerSuccess(data));
    yield call(action.payload.navigate("/resumo", { state: { data: action.payload.data } }));

    yield put(registerReset());
  } catch (error) {
    if (error.status === 403) {
      yield put(authRequest());
      yield put(retryRegister(action.payload.data));
    } else {
      yield put(showToastr({ type: "danger", message: error.data.message }));
      yield put(registerFailure(error));
    }
  }
}

export function* vendorsRequest() {
  try {
    const SECOND = 1000;
    const { data } = yield retry(5, 1 * SECOND, Api.get, "/vendors");
    yield put(vendorsSuccess(data));
  } catch (error) {
    yield put(vendorsFailure(error));
  }
}
