import React from "react";
import { MainContainer } from "components/container/MainContainer";
import cellWhats from "assets/img/cell-whats.png";
import "./howItWorksContainer.scss";

export const HowItWorksContainer = () => {
  return (
    <div className="como-funciona">
      <MainContainer>
        <div className="text">
          <h3 className="pb-2">C O M O&#x2800;F U N C I O N A</h3>

          <p className="f-title">
            Simples e fácil de <span>usar:</span>
          </p>

          <ul>
            <li>
              <p className="f-subtitle">1º passo:</p>
              <span>
                Após realizar a assinatura, você poderá realizar o download de
                nosso aplicativo.
              </span>
            </li>
            <li>
              <p className="f-subtitle">2º passo:</p>
              <span>
                Quando precisar, basta solicitar o agendamento das consultas e
                exames por meio do Aplicativo Cartão Nação Saúde, WhatsApp ou
                Central de Atendimento.
              </span>
            </li>
            <li>
              <p className="f-subtitle">3º passo:</p>
              <span>
                No dia do atendimento, basta apresentar o seu cartão virtual na
                clínica ou laboratório. Pronto! Você estará liberado para
                realizar o atendimento necessário.
              </span>
            </li>
          </ul>
        </div>

        <div className="image-container">
          <img src={cellWhats} alt="Atendimento Whats Cartao Nação Saúde" />
        </div>
      </MainContainer>
    </div>
  );
};

export default HowItWorksContainer;
