import React, { useEffect, useRef, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { MainContainer } from "components/container/MainContainer";
import CardPackage from "./cardPackage";
import { connect } from "react-redux";
import * as packagesActions from "store/packages/actions";
import { getVendor, replaceOnlyNumber } from "common/utils";
import "./oursPackagesContainer.scss";

export const OursPackagesContainer = ({
  packagesRequest,
  packagesState,
  auth_state,
  vendors,
}) => {
  const isFirstRender = useRef(true);
  const { search } = useLocation();
  const [packages, setPackages] = useState([]);
  const [vendor, setVendor] = useState(null);

  useMemo(() => {
    if (search && !!vendors.length) {
      setVendor(getVendor(replaceOnlyNumber(search), vendors));
    }
  }, [search, vendors]);

  useEffect(() => {
    if (!packagesState.loading && !!packagesState.packages.length) {
      setPackages(packagesState.packages);
    }
  }, [packagesState.loading, packagesState.packages]);

  useEffect(() => {
    if (
      isFirstRender.current ||
      (packagesState.packages.length === 0 &&
        (auth_state.success || auth_state.tokenVerify.success))
    ) {
      isFirstRender.current = false;
      packagesRequest();
    }
  }, [
    packagesRequest,
    packagesState.packages.length,
    auth_state.success,
    auth_state.tokenVerify.success,
  ]);

  return (
    <div className="ours-packages" id="pacotes">
      <MainContainer>
        <div className="col">
          <h3 className="title text-center">P A C O T E S</h3>
          <h3 className="text-center">
            Escolha o pacote ideal para <span>você</span> ou{" "}
            <span>sua família:</span>
          </h3>

          <div className="packages-container">
            {!!packages.length && !!vendors.length &&
              packages.map((item) => {
                if (item.active) {
                  return (
                    <CardPackage
                      key={item.id}
                      data={{
                        ...item,
                        vendor_cpf: vendor ? vendor.cpf : "",
                        vendor_user_id: vendor ? `${vendor.id}` : "",
                        vendor_name: vendor ? vendor.name : "",
                      }}
                    />
                  );
                } else {
                  return false;
                }
              })}
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    packagesState: state.packages.data,
    auth_state: state.auth,
    vendors: state.packages.vendors.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    packagesRequest: () => {
      dispatch(packagesActions.packagesRequest());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OursPackagesContainer);
