import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChildReaching } from "@fortawesome/free-solid-svg-icons";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import familyIcon from "assets/img/family-icon.png";
import { slugfy } from "common/utils";
import "./oursPackagesContainer.scss";

export const CardPackage = ({ data }) => {
  const regexIndividual = /\bindividual\b/i;

  return (
    <Card className={`rounded ${data.name.split(" ")[0].toLocaleLowerCase()}`}>
      <Card.Body>
        <Card.Title>
          {regexIndividual.test(data.name) ? (
            <FontAwesomeIcon
              icon={faChildReaching}
              size={"25px"}
              color={"#022f74"}
              className="pe-2 icon"
            />
          ) : (
            <img
              src={familyIcon}
              style={{ width: "auto", height: "20px" }}
              className="me-1 icon"
              alt="Icone Familia"
            />
          )}
          Plano {data.name}
        </Card.Title>
        <div className="container-pack">
          <div className="container-price m-0">
            <p className="classe">
              R${" "}
              {data.price.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                currency: "BRL",
              })}{" "}
              <span>por mês</span>
            </p>
          </div>
        </div>

        <ul className="card-description p-0">
          {data.package_details.map((carac) => {
            if (carac.included) {
              return (
                <li key={carac.id}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size={"lg"}
                    color={"#585858"}
                    className="me-2"
                  />
                  {carac.description}
                </li>
              );
            } else {
              return (
                <li className="inactive" key={carac.id}>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    size={"lg"}
                    color={"#E55555"}
                    className="me-2"
                  />
                  {carac.description}
                </li>
              );
            }
          })}
        </ul>

        <div className="text-center py-4">
          <Link
            className="btn-green assign"
            to={{
              pathname: slugfy(data.name),
            }}
            state={{
              packageId: data.id,
              vendor_cpf: data.vendor_cpf,
              vendor_user_id: data.vendor_user_id,
              vendor_name: data.vendor_name,
            }}
          >
            Assine agora
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardPackage;
