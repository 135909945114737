import React from "react";
import { MainContainer } from "components/container/MainContainer";
import mobile from "assets/img/new/mobile-icon.png";
import doctor from "assets/img/new/doctor-icon.png";
import computer from "assets/img/new/computer-icon.png";
import tooth from "assets/img/new/tooth-icon.png";
import graduation from "assets/img/new/graduation-cap-icon.png";
import hospital from "assets/img/new/hospital-icon.png";
import shield from "assets/img/new/shield-check-icon.png";
import syringe from "assets/img/new/syringe-icon.png";
// import scalpel from "assets/img/new/scalpel-icon.png";
import "./benefitsContainer.scss";
import GridComponent from "./gridComponent";

export const BenefitsContainer = () => {
  return (
    <div className="benefits">
      <MainContainer>
        <div className="benefits-text">
          <h3>B E N E F Í C I OS</h3>
          <p className="f-title">
            {/* Desfrute de <span>vantagens exclusivas</span> para cuidar de sua
            Família com o <h3>Cartão Nação Saúde</h3> */}
            Acesse e desfrute agora mesmo as <span>vantagens exclusivas</span> para você e sua família com o <h3>Cartão Nação Saúde</h3>
          </p>

          <a href="#pacotes" className="btn-green desktop">
            Assine agora
          </a>
        </div>

        <div className="grid">
          <GridComponent
            icon={mobile}
            name="Orientação médica on-line 24 horas"
          >
            <b>Orientação médica on-line 24 horas</b> por dia com médicos do
            renomado <b>Einstein Conecta.</b>
          </GridComponent>

          <GridComponent icon={doctor} name="Todas as especialidades médicas">
            <b>Todas as especialidades médicas</b> para realização de consultas
            presenciais e online com economia de até 80%.
          </GridComponent>

          <GridComponent
            icon={computer}
            name="Exames laboratoriais e de imagens"
          >
            {/* <b>Exames laboratoriais e de imagens</b> nos melhores laboratórios
            do país. */}
            <b>Exames laboratoriais e de imagens</b> nos melhores laboratórios do país. 
            Facilidade e rapidez no agendamento.

          </GridComponent>

          <GridComponent icon={tooth} name="Tratamentos odontológicos">
            <b>Tratamentos odontológicos</b> de baixa e alta complexidade,
            próteses, preventivos e estéticos.
          </GridComponent>

          <GridComponent
            icon={hospital}
            name="Rede de atendimento com mais de 10 mil parceiros."
          >
            {/* <b>Rede de atendimento com mais de 10 mil parceiros.</b> Contamos
            com atendimento em clínicas e laboratórios de renomados em todo
            país. */}
            <b>Rede de atendimento presencial com mais de 11 mil estabelecimentos ativos.</b>
            Contamos com atendimento nas melhores clínicas e laboratórios em todo Brasil.
          </GridComponent>

          {/* <GridComponent icon={scalpel} name="Cirurgias com preços acessíveis">
            <b>Cirurgias com preços acessíveis</b> e ótima qualidade. país.
          </GridComponent> */}

          <GridComponent
            icon={syringe}
            name="Descontos de até 30% em medicamentos"
          >
            {/* <b>Descontos de até 30% em medicamentos</b> nas principais drogarias
            do Brasil: São Paulo, Pacheco, Pague Menos, Drogasil, Droga Raia. */}

            <b>Descontos de até 35% em medicamentos</b>, higiene pessoal e cosméticos nas principais drogarias do Brasil: São Paulo, Pacheco, Pague Menos, Drogasil, Droga Raia. 

          </GridComponent>

          <GridComponent
            icon={shield}
            name="Seguros assistenciais pela Porto Seguro"
          >
            {/* <b>Seguros assistenciais pela Porto Seguro</b> nos momentos que você
            mais precisa. */}

            <b>Seguros assistenciais pela Porto Seguro:</b> Vida, acidentes pessoais, invalidez, auxílio funerário e você ainda concorre a R$5.500,00 todos os meses. 

          </GridComponent>

          <GridComponent
            icon={graduation}
            name="Educação presencial ou a distância"
          >
            {/* <b>Educação presencial ou a distância</b> na Universidade Cruzeiro
            do Sul com até 40% de desconto. */}

            <b>Educação presencial ou a distância</b> na Universidade Cruzeiro do Sul com até 50% de desconto e isenção da taxa de matrícula.

          </GridComponent>
        </div>

        <div className="align-items-center">
          <a href="#pacotes" className="btn-green mobile">
            Assine agora
          </a>
        </div>
      </MainContainer>
    </div>
  );
};

export default BenefitsContainer;
