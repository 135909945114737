import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableSaveEconomyComponent from "./tableSaveEconomyComponent";
import "./saveMoneyContainer.scss";

export const SaveMoneyContainer = () => {
  return (
    <div className="save-money">
      <Container fluid>
        <Row>
          <Col sm={4} xs={5}>
            <TableSaveEconomyComponent
              title={
                <h4 style={{ backgroundColor: "#00BF63", fontSize: "1.25rem" }}>
                  Exames
                </h4>
              }
              className={"green"}
            >
              <div style={{ backgroundColor: "#00BF63" }}>
                <p>
                  <b> Ressonância Magnética</b>
                </p>
                <p>
                  <b>Raio-X</b>
                </p>
                <p>
                  <b>Endoscopia</b>
                </p>
                <p>
                  <b>Colonoscopia</b>
                </p>
                <p>
                  <b>Tratamento Ortodôntico</b>
                </p>
              </div>
            </TableSaveEconomyComponent>
          </Col>
          <Col sm={3} xs={3}>
            <TableSaveEconomyComponent
              title={
                <h4 style={{ backgroundColor: "#747474", fontSize: "1.25rem" }}>
                  Particular
                </h4>
              }
              className={"gray"}
            >
              <div
                style={{ backgroundColor: "#747474" }}
                className="text-center"
              >
                <p>R$ 600</p>
                <p>R$ 70</p>
                <p>R$ 350</p>
                <p>R$ 580</p>
                <p>R$ 150</p>
              </div>
            </TableSaveEconomyComponent>
          </Col>
          <Col sm={3} xs={4}>
            <TableSaveEconomyComponent
              title={
                <h4 style={{ backgroundColor: "#004AAD", fontSize: "0.9rem" }}>
                  Cartão Nação
                  <br /> Saúde
                </h4>
              }
              className={"blue"}
            >
              <div
                style={{ backgroundColor: "#004AAD" }}
                className="text-center"
              >
                <p>
                  A partir de <b>R$ 250</b>
                </p>
                <p>
                  A partir de<b>R$ 25</b>
                </p>
                <p>
                  A partir de<b>R$ 130</b>
                </p>
                <p>
                  A partir de<b>R$ 255</b>
                </p>
                <p>
                  A partir de
                  <b>R$ 60</b>
                </p>
              </div>
            </TableSaveEconomyComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SaveMoneyContainer;
