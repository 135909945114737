import { createStore, applyMiddleware } from "redux";
import combinedReducers from "./combinedReducers";
import rootSaga from "./rootSagas";
import createSaga from "redux-saga";
import { loadStateSessionStorage, saveStateSessionStorage } from "common/utils";
import throttle from "lodash/throttle";
import { composeWithDevTools } from "redux-devtools-extension";

const sagaMiddleware = createSaga();

const persistedState = loadStateSessionStorage();

const store = createStore(
  combinedReducers,
  persistedState,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

store.subscribe(
  throttle(() => {
    saveStateSessionStorage({
      ...store.getState(),
    });
  }, 1000)
);

sagaMiddleware.run(rootSaga);

export { store };
