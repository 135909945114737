import { useEffect, useState } from "react";
import MainContainer from "components/container/MainContainer";
import logo from "assets/img/logo-cartao-saude.png";
import { useLocation, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";

import "./resume.scss";

const Resume = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    package_id: 0,
    payment_type: "",
    payment_token: "",
    vendor_cpf: "",
    vendor_user_id: "",
    name: "",
    email: "",
    gender: "",
    birth_date: "",
    mobile_number: "",
    cpf: "",
    rg: "",
    nationality: "",
    address: "",
    address_number: "",
    address_complement: "",
    district: "",
    city: "",
    state: "",
    zip_code: "",
  });
  const [birthDateBR, setBirthDateBR] = useState("");

  useEffect(() => {
    if (state?.data) {
      setData(state.data);

      setBirthDateBR(format(parseISO(state.data.birth_date), "dd/MM/yyyy"));
      return;
    } else {
      navigate("/");
    }
  }, [state, navigate]);

  return (
    <div className="resume">
      <div className="col text-center py-4 title">
        <img src={logo} className="img-fluid" alt="logo cartão saúde" />
        <h2 className="p-3 green-color">
          Seu pacote foi cadastrado com sucesso!!
        </h2>
        <p>Obrigado por escolher nos escolher!</p>
        <p>Caso tenha interesse, veja abaixo o resumo de sua solicitação.</p>
      </div>

      <MainContainer>
        <div className="col">
          <div className="row">
            <div className="col">
              <div className="row">
                <h3 className="pb-4 green-color">Resumo</h3>
                <h5 className="green-color">Dados Titular</h5>

                <div className="row content">
                  <div className="col">
                    <p>Nome: {data.name}</p>
                    <p>Email: {data.email}</p>
                    <p>Data de Nascimento: {birthDateBR}</p>
                    <p>Contato: {data.mobile_number}</p>
                    <p>CPF: {data.cpf}</p>
                    <p>RG: {data.rg}</p>
                    <p>Nacionalidade: {data.nationality}</p>
                  </div>
                  <div className="col">
                    <p>CEP: {data.zip_code}</p>
                    <p>Endereço: {data.address}</p>
                    <p>Número: {data.address_number}</p>
                    {data.address_complement && (
                      <p>Complemento: {data.address_complement}</p>
                    )}
                    <p>Bairro: {data.district}</p>
                    <p>Cidade: {data.city}</p>
                    <p>Estado: {data.state}</p>
                  </div>
                </div>
              </div>

              {data.dependents && data.dependents.length > 0 && (
                <div className="row">
                  <h5 className="green-color">Dependentes</h5>

                  <div className="row content">
                    {data.dependents.map((dependent, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div className="col">
                            <p>Nome: {dependent.name}</p>
                            <p>Email: {dependent.email}</p>
                            <p>Data de Nascimento: {dependent.birth_date}</p>
                            <p>Contato: {dependent.mobile_number}</p>
                            <p>CPF: {dependent.cpf}</p>
                            <p>RG: {dependent.rg}</p>
                          </div>
                        );
                      }

                      return (
                        <>
                          <div className="col">
                            <p>Nome: {dependent.name}</p>
                            <p>Email: {dependent.email}</p>
                            <p>Data de Nascimento: {dependent.birth_date}</p>
                            <p>Contato: {dependent.mobile_number}</p>
                            <p>CPF: {dependent.cpf}</p>
                            <p>RG: {dependent.rg}</p>
                          </div>
                          <div className="row"></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="row">
                <h5 className="green-color">Pacote e Forma de Pagamento</h5>

                <div className="row content">
                  <div className="col">
                    <p>
                      Pacote:{" "}
                      {data.package_id === 1
                        ? "Individual"
                        : data.package_id === 2
                        ? "Individual Plus"
                        : data.package_id === 3
                        ? "Familiar"
                        : data.package_id === 4
                        ? "Familiar Plus"
                        : ""}
                    </p>
                    <p>
                      Forma de Pagamento:{" "}
                      {data.payment_type === "1"
                        ? "Boleto Bancário"
                        : data.payment_type === "2"
                        ? "Cartão de Crédito"
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Resume;
