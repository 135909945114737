import React from "react";
import { MainContainer } from "components/container/MainContainer";
import family from "assets/img/family.png";
import heart from "assets/img/heart.png";
import "./differentialsContainer.scss";

export const DifferentialsContainer = () => {
  return (
    <div className="diferenciais">
      <MainContainer>
        <div className="image-container">
          <img src={family} alt="Família sorrindo" />
          <img src={heart} alt="Coração" className="heart"/>
        </div>

        <div className="text">
          <h3 className="pb-2">D I F E R E N C I A I S</h3>

          <p className="f-title">
            Somos a maior rede credenciada do <span>BRASIL:</span>
          </p>

          <ul>
            <li>
              <p className="f-subtitle">Temos mais de 11 mil parceiros</p>
              <span>
                Somos o cartão de benefícios feito para você e sua família.{" "}
                <b>
                  Tudo que você precisa em um só lugar. Atendimento completo em
                  saúde, além de segurança e educação.{" "}
                </b>
              </span>
            </li>
            <li>
              <p className="f-subtitle">
                Melhor e Mais Tradicional rede credenciada do mercado.
              </p>
              <span>
                Não somos um plano de saúde.{" "}
                <b>Nosso objetivo é facilitar sua vida</b> com um atendimento
                rápido, de qualidade e com preços acessíveis a{" "}
                <b>
                  consultas, terapias, medicamentos, dentistas e muito mais!
                </b>
              </span>
            </li>
            <li>
              <p className="f-subtitle">Qualidade e Custo Benefício</p>
              <span>
                <b>Milhares de associados usam e aprovam. Faça parte da nossa comunidade!</b> Uma
                nação só é forte se tem acesso a saúde, educação e prevenção.
                Satisfação garantida!
              </span>
            </li>
          </ul>
        </div>
      </MainContainer>
    </div>
  );
};

export default DifferentialsContainer;
