import React from "react";
import { MainContainer } from "components/container/MainContainer";
import womans from "assets/img/new/mulheres.png";
import check from "assets/img/new/check.png";
import "./advantagesContainer.scss";

export const AdvantagesContainer = () => {
  return (
    <div className="vantagens">
      <MainContainer>
        <div className="text">
          <h3 className="pb-2">V A N T A G E N S</h3>

          <p className="f-subtitle">
            <b>Por não sermos um plano de saúde,</b> você desfruta de inúmeras
            vantagens:
          </p>

          <ul>
            <li>
              <img src={check} alt="Ícone Check" />
              Utilização imediata sem carência;
            </li>
            <li>
              <img src={check} alt="Ícone Check" />
              Amplo suporte para doenças pré-existentes;
            </li>
            <li>
              <img src={check} alt="Ícone Check" />
              Atendimento sem restrição de idade;
            </li>
            <li>
              <img src={check} alt="Ícone Check" />
              Cobertura completa para gestantes;
            </li>
            <li>
              <img src={check} alt="Ícone Check" />
              Flexibilidade sem fidelidade contratual;
            </li>
            <li>
              <img src={check} alt="Ícone Check" />
              Processos descomplicados, sem burocracia;
            </li>
          </ul>

          <div className="text-center text-sm-start">
            <a href="#pacotes" className="btn-green desktop">
              Assine agora
            </a>
          </div>
        </div>

        <div className="image-container">
          <img src={womans} alt="Cartão Nação Saúde" />
        </div>
      </MainContainer>
    </div>
  );
};

export default AdvantagesContainer;
