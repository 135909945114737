import React from "react";
import { MainContainer } from "components/container/MainContainer";
import doublePhone from "assets/img/new/double-cellphone.png";
import "./medicalAppointments.scss";

export const MedicalAppointments = () => {
  return (
    <div className="consultas">
      <MainContainer>
        <div className="image-container">
          <img src={doublePhone} alt="Cartão Nação Saúde" />
        </div>

        <div className="text">
          <h3 className="pb-2">S A Ú D E&#x2800;A C E S S Í V E L</h3>

          <p className="f-title">
            {/* Cuide da sua família de{" "}
            <h3>forma prática, econômica e sem complicações </h3>
            com o Cartão Nação Saúde. */}

            Cuide da sua vida e de quem você ama de{" "}
            <h3>forma prática, econômica e sem complicações </h3>
            com o Cartão Nação Saúde.

          </p>

          <p className="f-subtitle">
            <b>
              O Cartão Nação Saúde oferece facilidade e preço acessível para
              auxiliar a saúde da sua família.
            </b>{" "}
            Com uma ampla rede de profissionais e serviços médicos conveniados,
            o cartão permite acesso rápido a consultas, exames e tratamentos.
          </p>

          <a
            href="https://nacaosaude.centraldeatendimento.me/red"
            className="btn-credenciados desktop"
            rel="noreferrer"
            target="_blank"
          >
            {/* VEJA NOSSA REDE DE CREDENCIADOS */}
            CONHEÇA NOSSA REDE CREDENCIADA
          </a>
        </div>

        <a
          href="https://nacaosaude.centraldeatendimento.me/red"
          className="btn-credenciados mobile"
          rel="noreferrer"
          target="_blank"
        >
          VEJA NOSSA REDE DE CREDENCIADOS
        </a>
      </MainContainer>
    </div>
  );
};

export default MedicalAppointments;
